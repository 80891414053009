import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Footer from '@gtg/components/footer'
import { useModal } from '@gtg/components/modal/useModal'
import Nav from '@gtg/components/nav'
import Seo from '@gtg/components/seo'
import media from '@gtg/utils/media-queries'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React, { useState } from 'react'
import styled from 'styled-components'
import GlobalWrapper from '../components/globalWrapper'

export const query = graphql`
  query MyQuery {
    images: allFile(filter: { relativePath: {} }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1500, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const ImageGrid = styled.div`
  background: #ecf2f8;
  border-radius: 10px;
  max-width: 100%;
  padding: 40px;
  margin: 32px 0;
  text-align: center;
  ${media.lg`
    padding: 32px;
    margin: 0 0 24px 0;
  `}
  ${media.md`
    margin: 0;
    margin: 0 0 16px 0;
  `}
  ${media.sm`
    padding: 16px;
  `}
`

const StyledImg = styled(Img)`
  height: 200px;
  width: 200px;
  margin: 8px;
  display: inline-block;
  ${media.sm`
    width: 100%;
    margin: 4px 0;
    height: 50vw;
  `}
`

const StyledButton = styled.button`
  background: transparent;
  padding: 0;
  margin: 0;
  :hover {
    background: transparent;
  }
  ${media.sm`
    width: 100%;
  `}
`

const FullImg = styled(Img)`
  width: 100%;
  height: 100%;
`

const Gallery = ({ data }) => {
  const { open, RenderModal } = useModal()
  const [image, setImage] = useState('')

  return (
    <GlobalWrapper>
      <Seo title="Galerie" />
      <Nav caps wMax="xl" withBorder />
      <RenderModal fullScreen>
        <FullImg objectFit="contain" fluid={image}></FullImg>
      </RenderModal>
      <section>
        <Container wMax="lg">
          <Box pxMax="md" pyMax="lg">
            <h1>Galerie</h1>
            <ImageGrid>
              {data.images.edges.map(({ node }, index) => (
                <Button
                  key={'image' + index}
                  image={node.childImageSharp.full}
                  open={open}
                  setImage={setImage}
                >
                  <StyledImg fluid={node.childImageSharp.thumb}></StyledImg>
                </Button>
              ))}
            </ImageGrid>
          </Box>
        </Container>
      </section>
      <Footer background="#0c1821" />
    </GlobalWrapper>
  )
}

export default Gallery

const Button = props => {
  return (
    <StyledButton
      onClick={() => {
        props.open()
        props.setImage(props.image)
      }}
    >
      {props.children}
    </StyledButton>
  )
}
